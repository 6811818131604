import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { IConcurso, IConcursos, IInformacao, IInformacoes, IJogo, IJogos } from "../interfaces";

const visualizacaoPDF = (jogos: IJogos, informacoes: IInformacoes, concursos: IConcursos) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const title     : Content = [
        {
            text: 'Apostas',
            fontSize: 15,
            bold: true,
            margin: [15, 20, 0, 45]
        }
    ];

    const dadosConcursos = concursos.map((dado: IConcurso) => {
        return [
            {text: dado.concurso,       fontSize: 9},
            {text: dado.data,           fontSize: 9},
            {text: dado.n1[0].numero,   fontSize: 9},
            {text: dado.n2[0].numero,   fontSize: 9},
            {text: dado.n3[0].numero,   fontSize: 9},
            {text: dado.n4[0].numero,   fontSize: 9},
            {text: dado.n5[0].numero,   fontSize: 9},
            {text: dado.n6[0].numero,   fontSize: 9},
        ]
    });

    const dadosInformacoes = informacoes.map((dado: IInformacao) => {
        return [
            {text: dado.dado,   fontSize: 9},
            {text: dado.valor,  fontSize: 9, alignment: 'right'},
        ]
    });

    const dadosJogos = jogos.map((dado: IJogo) => {
        return [
            {text: dado.nome,           fontSize: 9},
            {text: dado.n1[0].numero,   fontSize: 9,    background: dado.n1[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n2[0].numero,   fontSize: 9,    background: dado.n2[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n3[0].numero,   fontSize: 9,    background: dado.n3[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n4[0].numero,   fontSize: 9,    background: dado.n4[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n5[0].numero,   fontSize: 9,    background: dado.n5[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n6[0].numero,   fontSize: 9,    background: dado.n6[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n7[0].numero,   fontSize: 9,    background: dado.n7[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n8[0].numero,   fontSize: 9,    background: dado.n8[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n9[0].numero,   fontSize: 9,    background: dado.n9[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.n10[0].numero,  fontSize: 9,    background: dado.n10[0].acertou ? '#42e6f5' : 'white'},
            {text: dado.pontos,         fontSize: 9,    background: dado.pontos === '10' ? '#42f557' : 'white',     alignment: 'center'}
        ]
    });

    const details   : Content = [
        {
            table: {
                headerRows: 1,
                widths: ['*',16,16,16,16,16,16,16,16,16,16,34],
                body: [
                    [   // Colunas primeira linha
                        {text: 'Nome', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 1', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 2', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 3', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 4', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 5', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 6', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 7', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 8', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 9', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 10', style: 'tableHeader', fontSize: 10},
                        {text: 'Pontos', style: 'tableHeader', fontSize: 10}
                    ], 
                    ...dadosJogos
                ]
            },
            layout: 'headerLineOnly'
        },
        {
            table: {
                headerRows: 1,
                widths: ['*', 100],
                body: [
                    [   // Colunas primeira linha
                        {text: 'Informação', style: 'tableHeader', fontSize: 10},
                        {text: 'Dado', style: 'tableHeader', fontSize: 10},
                    ], 
                    ...dadosInformacoes
                ]
            },
            layout: 'headerLineOnly'
        }, 
        {
            table: {
                headerRows: 1,
                widths: ['*', 100, 32, 32, 32, 32, 32, 32],
                body: [
                    [   // Colunas primeira linha
                        {text: 'Concurso', style: 'tableHeader', fontSize: 10},
                        {text: 'Data', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 1', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 2', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 3', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 4', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 5', style: 'tableHeader', fontSize: 10},
                        {text: 'Nº 6', style: 'tableHeader', fontSize: 10},
                    ], 
                    ...dadosConcursos
                ]
            },
            layout: 'headerLineOnly'
        }, 
    ];

    const footer = (currentPage: number , pageCount: number) : Content => {
        return [
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'center',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    }

    const docDefinitions : TDocumentDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50 , 15 , 40], //left top right bottom

        header: [title],
        content: [details],
        footer: footer
    }

    pdfMake.createPdf(docDefinitions).open();
}

export default visualizacaoPDF;