import { useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Tabela from "../../Components/table";
import visualizacaoPDF from "../../Reports/visualizacao";
import useAPI from "../../Hooks/useAPI";

const Dashboard = () => {
    const {idJogo} = useParams<{idJogo?: string}>();
    const navigate = useNavigate();
    const API = useAPI();
    const [portrait, setPortrait] = useState<boolean>(false);

    const [filtro, setFiltro] = useState<string>('');
    const [filtroPontos, setFiltroPontos] = useState<number>(0);
    const [tpFiltro, setTpfiltro] = useState<string>('nome')
    const [ordenacao, setOrdenacao] = useState<string>('pontos');

    const query = useQuery(
        idJogo as any,
        () => API.get('visualizacaoJogo', {'JOGO': idJogo!}),//fetchDados(idJogo!),
        {}
    );

    const dados = useMemo(() => 
        query.isLoading || query.isError ?
            [] 
        : 
            query.data['cartoes'].filter((dado : any) => {
                if ( (tpFiltro === 'nome') && (filtro.length > 1) ) {
                    return (dado[tpFiltro].indexOf(filtro.toUpperCase()) !== -1)
                } else if (tpFiltro === 'pontos') {
                    return (parseInt(dado[tpFiltro]) === filtroPontos)
                } else {
                    return dado;
                };  
            }).sort((a : any, b : any) => {
                return b[ordenacao] - a[ordenacao]
                // if (a[ordenacao] < b[ordenacao]) {
                //     return ordenacao === 'nome' ? -1 : 1;
                // } else if (a[ordenacao] > b[ordenacao]) {
                //     return ordenacao === 'nome' ? 1 : -1;
                // } else {
                //     return null;
                // };
            })
    , [query.data, query.isError, query.isLoading, filtro, ordenacao, tpFiltro, filtroPontos]);

    const dadosConcurso = useMemo(() => 
        query.isLoading || query.isError ?
            [] 
        : 
            query.data['concursos']
    , [query.data, query.isError, query.isLoading]);

    const dadosInformacoes = useMemo(() => 
        query.isLoading || query.isError ?
            [] 
        : 
            query.data['informacoes']
    , [query.data, query.isError, query.isLoading]);

    const dadosGanhador = useMemo(() => 
        query.isLoading || query.isError ?
            [] 
        : 
            query.data['cartoes'].filter((dado : any) => {
                if ( dado['pontos'] === "10" ) {
                    return dado;
                } else {
                    return null;
                };  
            })
    , [query.data, query.isError, query.isLoading]);

    const colunas = useMemo(() => [
        {
            id: 'colunas',
            Header: <div className="verticalCenterFull">{`${query.isLoading || query.isError ? '' : query.data['jogo']}`}</div>,
            columns: [
                {
                    Header: "Nome",
                    accessor: "nome",
                },
                {
                    Header: "Nº 1",
                    accessor: "n1[0]",
                    Cell: (row : any) => <h5>
                    <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                        {row.value['numero']}
                    </Badge>
                </h5>
                },
                {
                    Header: "Nº 2",
                    accessor: "n2[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 3",
                    accessor: "n3[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 4",
                    accessor: "n4[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 5",
                    accessor: "n5[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 6",
                    accessor: "n6[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 7",
                    accessor: "n7[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 8",
                    accessor: "n8[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 9",
                    accessor: "n9[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 10",
                    accessor: "n10[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Pontos",
                    accessor: "pontos",
                    Cell: (row : any) => <h5>
                        <Badge bg={row.value === '10' ? "success" : "dark"} text={"light"}>
                            {row.value}
                        </Badge>
                    </h5>
                },
            ]
        }
    ], [query.data, query.isError, query.isLoading]); 

    const colunasConcurso = useMemo(() => [
        {
            id: 'colunasConcurso',
            Header: <div className="verticalCenterFull">{`Concursos`}</div>,
            columns: [
                {
                    Header: "Concurso",
                    accessor: "concurso",
                },
                {
                    Header: "Data",
                    accessor: "data",
                },
                {
                    Header: "Nº 1",
                    accessor: "n1[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={"light"} text={"dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 2",
                    accessor: "n2[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={"light"} text={"dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 3",
                    accessor: "n3[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={"light"} text={"dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 4",
                    accessor: "n4[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={"light"} text={"dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 5",
                    accessor: "n5[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={"light"} text={"dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 6",
                    accessor: "n6[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={"light"} text={"dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
            ]
        }
    ], []);  

    const colunasInformacoes = useMemo(() => [
        {
            id: 'colunasDadosJogo',
            Header: <div className="verticalCenterFull">{`Dados do Jogo`}</div>,
            columns: [
                {
                    Header: "Informação",
                    accessor: "dado",
                },
                {
                    Header: "Dado",
                    accessor: "valor",
                    Cell: (row : any) => <div className="text-md-end">
                        {row.value}
                    </div>
                },
            ]
        }
    ], []);  

    const colunasGanhador = useMemo(() => [
        {
            id: 'colunas',
            Header: <div className="verticalCenterFull">Ganhadores</div>,
            columns: [
                {
                    Header: "Nome",
                    accessor: "nome",
                },
                {
                    Header: "Nº 1",
                    accessor: "n1[0]",
                    Cell: (row : any) => <h5>
                    <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                        {row.value['numero']}
                    </Badge>
                </h5>
                },
                {
                    Header: "Nº 2",
                    accessor: "n2[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 3",
                    accessor: "n3[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 4",
                    accessor: "n4[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 5",
                    accessor: "n5[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 6",
                    accessor: "n6[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 7",
                    accessor: "n7[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 8",
                    accessor: "n8[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 9",
                    accessor: "n9[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Nº 10",
                    accessor: "n10[0]",
                    Cell: (row : any) => <h5>
                        <Badge pill bg={row.value['acertou'] ? "primary" : "light"} text={row.value['acertou'] ? "light" : "dark"}>
                            {row.value['numero']}
                        </Badge>
                    </h5>
                },
                {
                    Header: "Pontos",
                    accessor: "pontos",
                    Cell: (row : any) => <h5>
                        <Badge bg={row.value === '10' ? "success" : "dark"} text={"light"}>
                            {row.value}
                        </Badge>
                    </h5>
                },
            ]
        }
    ], []); 

    console.log(dadosGanhador);

    useEffect(() => { 
        setPortrait(window.matchMedia("(orientation: portrait)").matches);

        if (query.isError) {
            console.log('erro: jogo nao encontrado');
            alert('Jogo ainda não disponível para visualização! Tente novamente mais tarde.');
            //navigate("/");
        };
    }, [query.isError, navigate, setPortrait]);

    return (
        query.isLoading ?
            <Container className="verticalCenter">
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Carregando...</span>
                </Spinner>
            </Container>
        :
            <>
                {
                    portrait ?  // Mobile
                        <>
                            <Container className="verticalCenterFull">
                                <Form className="mx-3 my-3 w-100">
                                    <Row className="mb-3">
                                        <InputGroup size="lg">
                                            <InputGroup.Text id="filtrar">Filtrar por:</InputGroup.Text>
                                            <Form.Select onChange={(e) => setTpfiltro(e.target.value)} aria-label="" aria-describedby="tpfiltro">
                                                <option value={'nome'}>Nome</option>
                                                <option value={'pontos'}>Pontuação</option>
                                            </Form.Select>
                                            {
                                                tpFiltro === 'nome' ?
                                                    <Form.Control onChange={e => setFiltro(e.target.value)} placeholder="Nome..." aria-describedby="filtrar"/>
                                                :
                                                    <Form.Control type="number" onChange={e => setFiltroPontos(parseInt(e.target.value))} min={'0'} max={'10'} value={filtroPontos}></Form.Control>
                                            }                                            
                                            <Button variant="primary" id="filtra"> <FaSearch /> </Button>
                                        </InputGroup>
                                    </Row>
                                    <Row className="mt-3">
                                        <InputGroup size="lg">
                                            <InputGroup.Text id="ordenar">Ordenar por:</InputGroup.Text>
                                            <Form.Select onChange={(e) => setOrdenacao(e.target.value)} aria-label="Ordenar por..." aria-describedby="ordenar">
                                                <option value={'pontos'}>Pontuação</option>
                                                <option value={'nome'}>Nome</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Row>
                                </Form>
                            </Container>

                            {
                                JSON.stringify(dadosGanhador) !== '[]' ?
                                    <Tabela colunas={colunasGanhador} dados={dadosGanhador}></Tabela>
                                :
                                    null
                            }
                            <Tabela colunas={colunas} dados={dados}></Tabela>
                            <Tabela colunas={colunasInformacoes} dados={dadosInformacoes}></Tabela>
                            <Tabela colunas={colunasConcurso} dados={dadosConcurso}></Tabela>

                            <Button variant="primary" className="mb-3" onClick={() => visualizacaoPDF(dados, dadosInformacoes, dadosConcurso)}>Salvar PDF</Button>
                        </>     
                    :           // Desktop
                        <Container className="verticalCenterFull">
                            <Form className="mx-3 my-3 w-100">
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text id="filtrar">Filtrar por:</InputGroup.Text>
                                            <Form.Select onChange={(e) => setTpfiltro(e.target.value)} aria-label="" aria-describedby="tpfiltro">
                                                <option value={'nome'}>Nome</option>
                                                <option value={'pontos'}>Pontuação</option>
                                            </Form.Select>
                                            {
                                                tpFiltro === 'nome' ?
                                                    <Form.Control onChange={e => setFiltro(e.target.value)} placeholder="Nome..." aria-describedby="filtrar"/>
                                                :
                                                    <Form.Control type="number" onChange={e => setFiltroPontos(parseInt(e.target.value))} min={'0'} max={'10'} value={filtroPontos}></Form.Control>
                                            }                                            
                                            <Button variant="primary" id="filtra"> <FaSearch /> </Button>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text id="ordenar">Ordenar por:</InputGroup.Text>
                                            <Form.Select onChange={(e) => setOrdenacao(e.target.value)} aria-label="Ordenar por..." aria-describedby="ordenar">
                                                <option value={'pontos'}>Pontuação</option>
                                                <option value={'nome'}>Nome</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Form>
                            
                            {
                                JSON.stringify(dadosGanhador) !== '[]' ?
                                    <Tabela colunas={colunasGanhador} dados={dadosGanhador}></Tabela>
                                :
                                    null
                            }
                            <Tabela colunas={colunas} dados={dados}></Tabela>
                            <Tabela colunas={colunasInformacoes} dados={dadosInformacoes}></Tabela>
                            <Tabela colunas={colunasConcurso} dados={dadosConcurso}></Tabela>

                            <Button variant="primary" className="mb-3" onClick={() => visualizacaoPDF(dados, dadosInformacoes, dadosConcurso)}>Salvar PDF</Button>
                        </Container>
                }
            </>
    );
}

export default Dashboard;