// import {useEffect} from "react";

const Redirect = ({page}: any) => {
    // useEffect(() => {
    //     window.location.replace(page)
    // }, [page])

    return (
        <></>
    )
}

export default Redirect;