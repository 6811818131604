const useAPI = () => {
    enum HttpMethod {
        Post    = "POST",
        Get     = "GET",
        Put     = "PUT",
        Delete  = "DELETE"
    };

    const generateUrlParams = (method: HttpMethod, values: any) : string => {
        if ((values !== '')) {
            return new URLSearchParams(values).toString();
        } else {
            return '';
        };
    };

    const post = async (endpoint: string, values: any) => {
        return call(HttpMethod.Post, endpoint, values);
    };

    const get = async (endpoint: string, values: any) => {
        return call(HttpMethod.Get, endpoint, values);
    };

    const put = async (endpoint: string, values: any) => {
        return call(HttpMethod.Put, endpoint, values);
    };

    const del = async (endpoint: string, values: any) => {
        return call(HttpMethod.Delete, endpoint, values);
    };

    const postArray = async (endpoint: string, values: any, array: any) => {
        return call(HttpMethod.Get, endpoint, values, array);
    };

    const call = async (method: HttpMethod, endpoint: string, values: any, teste?: any) => {
        let errors = false;
        let returnValue;
        let paramUrl = generateUrlParams(method, values);
        const arrayTeste = typeof(teste) === "undefined" ? '' : teste;

        if (method === HttpMethod.Get || method === HttpMethod.Delete || method === HttpMethod.Post || method === HttpMethod.Put) {
            return fetch(`${process.env.REACT_APP_API}/${endpoint}?${paramUrl+arrayTeste}`, {
                method: method,
                headers: {}
            })
            .then(data => {
                if(!data.ok) {
                    errors = true;
                }
                return data.json();
            })
            .then((response) => {
                if(errors) {
                    returnValue = response;
                    throw new Error(JSON.stringify(returnValue));
                }
                return response;
            })
            .catch(err => {throw new Error(err)})
        } 
        // else {
        //     return fetch(`${process.env.REACT_APP_API}/${endpoint}`, {
        //         method: method,
        //         headers: {},
        //         body: JSON.stringify(values)
        //     })
        //     .then(data => {
        //         if(!data.ok) {
        //             errors = true;
        //         }
        //         return data.json();
        //     })
        //     .then((response) => {
        //         if(errors) {
        //             returnValue = response;
        //             throw new Error(returnValue)
        //         }
        //         return response;
        //     })
        //     .catch(err => {console.log(err)})
        // };
    };
    return {
        get,
        post,
        put,
        del,
        postArray
    }
}
export default useAPI;