import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Redirect from "./Components/redirect";
// import useAuth from "./Hooks/useAuth";
// import Autenticacao from "./Pages/Auth";

const Rotas = () => {
    // const {phone, setPhone, idPessoa, setIdPessoa} = useAuth();

    // if (!phone! && !idPessoa!) {
    //     return (
    //         <Autenticacao setIdPessoa={setIdPessoa} setPhone={setPhone} />       
    //     );
    // };

    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path={'dashboard'} element={<></>} /> */}

                {/* <Route path={'jogo/:id'} element={<></>} /> */}
                <Route path="/" element={<Redirect page={process.env.REACT_APP_ANGULAR_APP!} />} />
                <Route path="/:idJogo" element={<Dashboard></Dashboard>} />
            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;