import './App.scss';
import Rotas from './routes';
import { QueryClient, QueryClientProvider } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";

const queryDefault = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 300000, // TODO: botar um cache de 15 minutos
        },
    },
});

const localStoragePersistor = createWebStoragePersistor({storage: window.sessionStorage});

persistQueryClient({
    queryClient: queryDefault,
    persistor: localStoragePersistor,
});

function App() {
	return (
		<QueryClientProvider client={queryDefault}>
			<Rotas />
		</QueryClientProvider>
	);
}

export default App;
